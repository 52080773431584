.App {
  padding: 32px;
}

.MainFlex {
  width: 100%;
}

.NewConversationInput {
  margin: 16px 0;
}

.ConnectionState, .ConnectionButton {
  margin-bottom: 16px;
}

.MessagesAreaMessages {
  display: flex;
  height: 400px;
  flex-direction: column;
}

.MessagesHeader {
  flex: 0 0 30px;
}

.MessagesArea {
  flex: 1;
  overflow: auto;
}

.MessagesInputsArea {
  flex: 0 0 30px;
}

.UserIdInput, .ConnectionUrlInput {
  margin: 16px 0;
}
